import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const OutSourcedModal = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="modal_body">
          <div className="titleCloseBtn">
            <button
              onClick={() => {
                props.setOpenModal(false);
              }}
            >
              X
            </button>
          </div>
          <h5 className="" style={{ color: "red", textAlign: "center" }}>
            Outsourced Plan
          </h5>
          <div className="outsource-container">
            <div className="outsource-content w-100 text-black">
              <ul className="outsource-content-ul text-black">
                <li>
                  Unlimited access to all Legal, HR and Standard Business docs
                  on Law For Me
                </li>
                <li>Dedicated account manager</li>
                <li>
                  Monitoring of company email address (inc, replying to internal
                  and external emails)
                </li>
                <li>Attendance at internal company meetings when required</li>
                <li>Access to Legal consultations when required</li>
                <li>Senior HR and Legal expects available when required</li>
                <li>
                  Legal Expenses Insurance Product paid separately (No hours
                  used if policy is allowed for litigation)
                </li>
              </ul>
            </div>
            <div className="outsource-content w-100 text-black">
              <ul className="outsource-content-ul text-black">
                <li>
                  416 hours of Legal and HR support per year (inc. advice,
                  drafting, representation, mediation, HR consultancy &
                  attendance-based on a 8 hours per week working time.These
                  hours can be rolled over for the following week as and when
                  required.
                </li>

                <li>One business coaching & tax consultation (1hr each)</li>
                <li>
                  The Law For Me International Partner's Programme(attending
                  networking events and connecting with experts when required
                  with free consultations
                </li>
                <li>
                  If further hours are required you will only be charged £95
                  plus Vat per hour
                </li>
              </ul>
            </div>
          </div>
        </Modal.Body>
        <div className="outsource-btn">
          <button
            onClick={(e) => {
              e.preventDefault();

              window.scrollTo(0, 0);
              localStorage.setItem("pricing_id", props.plan[4].stipePriceId);
              localStorage.setItem("price", props.plan[4].price);
              localStorage.setItem("planName", props.plan[4].plan);
              localStorage.setItem("planId", props.plan[4]._id);

              return navigate("/checkout-subscription-plan");
            }}
          >
            Get Started
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default OutSourcedModal;
