import React, { useEffect, memo, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Config from "../../config";
import {createSlug} from "../Widgets/Slug/Slug"

const Footer = () => {
  const navigate = useNavigate();
  const newsLetterCheck = useRef();
  const newsLetterEmail = useRef();
  
  const newsLetterSubmit = useRef();
  const [servicesF, setServicesF] = useState([]);
  const [legalNavF, setLegalNavF] = useState([]);
  const [email, setEmail] = useState("");
  let currentURL = window.location.pathname;
  const url = currentURL.split("/");
  currentURL = url[1];
  const topFunction = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const ValidateEmail = (input) => {
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (input.value.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  };

  const newsLetter = (e) => {
    e.preventDefault();
    if (ValidateEmail(newsLetterEmail.current)) {
      if (newsLetterCheck.current.checked) {
        newsLetterSubmit.current.classList.add("disabled");
        Config.post("/user/news-latter", { email: email })
          .then((res) => {
            console.log(res);
            if (res.status) {
              Config.showToast(
                "Thank you for subscribing to our LFM Legal Updates Newsletter.",
                "success",
                5000
              );
              newsLetterSubmit.current.classList.remove("disabled");
              newsLetterCheck.current.checked = false;
              newsLetterEmail.current.value = "";
            } else {
              Config.showToast(res.message, "danger", 5000);
              newsLetterSubmit.current.classList.remove("disabled");
            }
          })
          .catch((err) => {
            console.log(err, "newsLetter");
            newsLetterSubmit.current.classList.remove("disabled");
          });
      } else {
        newsLetterCheck.current.focus();
        Config.showToast(
          "Please check this box if you want to proceed.",
          "danger",
          4000
        );
      }
    } else {
      newsLetterEmail.current.focus();
      Config.showToast("Please enter a valid email address.", "danger", 4000);
    }
  };

  const legalServiceData = () => {
    Config.get(`/user/legal-doc-category`)
      .then((res) => {
        if (res.status) {
          setLegalNavF(res.data);
        }
      })
      .catch((err) => {
        console.log(err, "legalServiceData");
      });
  };

  const legalDocData = () => {
    Config.get(`/user/legal-services?limit=10`)
      .then((res) => {
        if (res.status) {
          setServicesF(res.data);
        }
      })
      .catch((err) => {
        console.log(err, "legalDocData");
      });
  };

  useEffect(() => {
    legalServiceData();
    legalDocData();
  }, []);
  return (
    <>
      <footer className=" text-white pt-80 pb-20 footerNext">
        <div className="container">
          <div className="row gy-50">
            <div className="col-12 col-sm-6 col-lg-6 col-md-6 footer-signUp">
              <h2 className="text-white">Sign Up For Legal Updates</h2>
              <p className="mt-20 font-size-15">
                If you want more information on latest legal updates, business
                tips, and advisory material then sign up to our 'Pen & Paper'
                newsletter and also get rewards and discounts on our services.
              </p>
            </div>
            <form
              onSubmit={newsLetter}
              className="col-12 col-sm-6 col-lg-5 col-md-5 offset-md-1"
            >
              <div className="row">
                <div className="col-md-8 col-xs-7 col-sm-7">
                  <input
                    required
                    className="form-control form-control-white input_border_bottom"
                    type="email"
                    placeholder="Your email address"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    ref={newsLetterEmail}
                  />
                </div>
                <div className="col-4 col-xs-4 col-sm-4">
                  <button
                    type="submit"
                    ref={newsLetterSubmit}
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   newsLetter();
                    // }}
                    className="btn d-sm-inline-flex btn btn-sm btn-accent-1 ms-auto me-30 me-lg-0 order-2 order-lg-3"
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-check form-check-white mt-20">
                    <input
                      ref={newsLetterCheck}
                      className="form-check-input for-cursor"
                      type="checkbox"
                      required
                    />
                    <label
                      className="text-justify font-size-15"
                      htmlFor="personalData"
                    >
                      By checking this box, I agree to the{" "}
                      <a
                        className="text-white "
                        href="/privacy-policy"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/privacy-policy");
                        }}
                      >
                        {" "}
                        privacy policy
                      </a>
                      , subscribing to updates, newsletters and marketing
                      information by e-mail from Law For Me
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <hr />
          <div className="row gy-50">
            <div className="col-12 col-sm-5 col-lg-3 col-md-3 footer-signUp">
              <a
                className="d-block mb-30"
                onClick={() => {
                  window.scrollTo(0, 0);
                  return navigate("/");
                }}
              >
                <img
                  // src="/assets/img/white_logo.png"
                  src="/assets/img/svg/white_logo.svg"
                  alt=""
                  className="for-cursor"
                />
              </a>
              <p className="font-size-15 mb-35">
                Providing you with unlimited legal and HR documents whenever you
                need them, how to guides, and legal advice from our array of
                lawyers.
              </p>
              <ul className="nav text-white align-items-center mb-20 nav-gap-md nav-no-opacity footer-logo">
                <li className="nav-item">
                  <a
                    className="nav-link for-cursor"
                    target="_blank"
                    href="https://www.facebook.com/LawForMe1/"
                  >
                    <img src="/assets/img/svg/Facebook.svg" alt="" />
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link for-cursor"
                    href="https://www.linkedin.com/company/law-for-me/"
                    target="_blank"
                  >
                    <img src="/assets/img/svg/Linkedin.svg" alt="" />
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link for-cursor"
                    href="https://twitter.com/law_for_me_"
                    target="_blank"
                  >
                    <img src="/assets/img/svg/round_twitter.svg" alt="" />
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link for-cursor"
                    href="https://www.instagram.com/law_for_me_/"
                    target="_blank"
                  >
                    <img src="/assets/img/svg/round_insta.svg" alt="" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-2 d-none d-md-block d-lg-block" />
            <div className="col-12 col-lg-7 col-md-7 col-sm-7">
              <div className="row gy-50">
                <div className="col-sm-5 col-xs-5 col-md-5 footer-list1">
                  <h6 className="display-6 text-white mb-20">
                    Legal Documents
                  </h6>
                  <ul className="nav flex-column text-white nav-opacity nav-gap-sm">
                    {legalNavF.map((data) => (
                      <li className="nav-item" key={data._id}>
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            const slug = createSlug(data.name);
                            navigate(
                              `/legal-document/${slug}`
                            );
                          }}
                          className="nav-link for-cursor"
                        >
                          {data.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-sm-7 col-xs-7 col-md-7 text-lg-end footer-list2">
                  <h6 className="display-6 text-white mb-20 text-lg-start">
                    Legal Services
                  </h6>
                  <ul className="nav flex-column text-white nav-opacity nav-gap-sm">
                    {servicesF.map((data) => (
                      <li className="nav-item" key={data._id}>
                      { data.name !='Immigration' &&  <a
                          onClick={() => {
                            const slug = createSlug(data.name);
                            navigate(`/legal-service/${slug}`);
                             }}
                          className="nav-link for-cursor"
                        >
                          {data.name}
                        </a>}
                      </li>
                    ))}
                  </ul>
                  <img
                    src="/assets/img/svg/Arrow-Circle.svg"
                    onClick={(e) => {
                      e.preventDefault();
                      topFunction();
                    }}
                    alt=""
                    style={{ width: "65px", cursor: "pointer", float: "right", height:"auto" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row d-flex align-items-center gy-25">
            <div className="col-12 col-lg-2 col-md-2 text-center text-lg-start" />
            <div className="col-12 col-lg-8 mt-4 col-md-12 ">
              <ul className="nav text-white nav-opacity nav-gap-lg justify-content-center">
                <li className="nav-item d-flex align-items-center">
                  <Link className="nav-link for-cursor" to="/terms-of-use">
                    Terms Of Use
                  </Link>
                  |
                </li>
                <li className="nav-item d-flex align-items-center">
                  <Link className="nav-link for-cursor" to="/business-of-use">
                    Terms of Business
                  </Link>
                  |
                </li>
                <li className="nav-item d-flex align-items-center">
                  <Link className="nav-link for-cursor" to="/privacy-policy">
                    Privacy Policy
                  </Link>
                  |
                </li>
                <li className="nav-item d-flex align-items-center">
                  <Link className="nav-link for-cursor" to="/cookie-policy">
                    Cookie Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-2 col-md-2 text-lg-end text-center"></div>
          </div>
        </div>
      </footer>
      {/* Vendors*/}
      <section className="footer_end">
        <div className="container">
          <div className="row d-flex align-items-center gy-25">
            <div className="col-12 col-lg-2 col-md-2 text-center text-lg-start" />
            <div className="col-12 col-lg-8 col-md-12 text-center">
              <p className="font-size-13 text-muted mt-10 mb-5">
                {/* Copyright 2022 KB and Co Ltd t/a Law For Me. */}
                Law For Me Tech Ltd
              </p>
              <p className="font-size-11 text-muted mb-10">
                Law For Me Tech Ltd is not a regulated entity and is not
                authorised by the Solicitors Regulation Authority, the Bar
                Standards Board or CILEX
              </p>
            </div>
            <div className="col-12 col-lg-2 col-md-2 text-lg-end text-center"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default memo(Footer);
