import React, { useRef } from "react";
import { useState, useEffect } from "react";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import Slider from "react-slick";
import "react-lazy-load-image-component/src/effects/blur.css";
import OurTeam from "../../Common/OurTeam";
import ServiceLine from "../../Common/ServiceLine";
import Feedback from "../../Common/Feedback";
import JoinOurTeam from "../../Common/JoinOurTeam";
// import PlanPopup from "../../Common/PlanPopup";
import OutSourcedModal from "../../Common/OutSourcedModal";
import Config from "../../../config";
import LegalServiceCom from "../../Common/LegalServiceCom";
// import { FetchProduct, GetProduct } from "../../../redux/action";
// import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { createSlug } from "../../Widgets/Slug/Slug";

const Home = () => {
  const loaderMainWrap = useRef();
  const faSpinInner = useRef();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [legalDocs, setLegalDocs] = useState([]);
  const plan_id = localStorage.getItem("plan_id");
  const [isLoading, setLoading] = useState(false);
  // const [isLoadingDoc, setLoadingDoc] = useState(false);
  const [reviewData, setReviewData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen1, setModalOpen1] = useState(false);
  const [legalNav, setLegalNav] = useState([]);
  const [deminsions, setDeminsions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  let isTrue = true;

  const legalServiceSlider = {
    infinite: false,
    speed: 400,
    slidesToShow: 4,
    slidesToScroll: 3,
    centerPadding: "100px",
    arrows: true,
    centerMode: false,
    initialSlide: 0,
    // centerMode: false,
    // centerPadding: "100px",
    // infinite: false,
    // slidesToShow: 3,
    // slidesToScroll: 1,
    // initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          centerMode: false,
          centerPadding: "40px",
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerMode: false,
          centerPadding: "40px",
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerMode: false,
          centerPadding: "40px",
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const reviewSlider = {
    centerMode: false,
    centerPadding: "100px",
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const svg = [
    [
      "/assets/img/svg/briefcase -white.svg",
      "/assets/img/svg/briefcase.svg",
      // "/assets/img/dummy_animation2.png",
      "/assets/img/svg/dummy_animation2.svg",
    ],
    [
      "/assets/img/svg/lamp.svg",
      "/assets/img/svg/lamp - pink.svg",
      // "/assets/img/dummy_animation.png",
      "/assets/img/svg/dummy_animation.svg",
    ],
    [
      "/assets/img/svg/honesty.svg",
      "/assets/img/svg/honesty - pink.svg",
      // "/assets/img/dummy_animation3.png",
      "/assets/img/svg/dummy_animation3.svg",
    ],
    [
      "/assets/img/svg/agreement.svg",
      "/assets/img/svg/agreement -pink.svg",
      // "/assets/img/dummy_animation4.png",
      "/assets/img/svg/dummy_animation4.svg",
    ],
  ];
  const [howItWorkImg, setHowItWorkImg] = useState(0);
  const [legalCategoryData, setLegalCategoryData] = useState([]);
  const [dataforlegalservice, setDataforlegalservice] = useState([]);
  const [selectedLawyer, setSelectedLawyer] = useState(0);

  const [allPlan, setAllPlan] = useState([]);
  // const [allLawyer, setAllLawyer] = useState([]);
  // const product=useSelector((state)=>state.productReducer.product);
  // const cart=useSelector((state)=>state.cartReducer.cart);
  // console.log("kjhjkdshcixk" , cart)

  //   let {title,price}=product;
  //     console.log(title,price);
  //   const dispatch=useDispatch();
  // useEffect(()=>{
  // dispatch(FetchProduct());
  // dispatch(GetProduct());
  // },[])
  // console.log(legalCategoryData);
  const getPlans = () => {
    Config.get(`/user/plans?limit=10`)
      .then((res) => {
        if (res.status) {
          setAllPlan(res.data);
          console.log(res.data.length);
        } else {
          console.log("error in getPlans");
        }
      })
      .catch((err) => {
        console.log(err, "getPlans");
      });
  };

  const legalDocData = () => {
    Config.get(`/user/legal-doc-category`)
      .then((res) => {
        if (res.status) {
          setLegalNav(res.data);
        }
      })
      .catch((err) => {
        console.log(err, "legalDocData");
      });
  };

  const serviceFun = (id) => {
    navigate(`/legal-service/${id}`);
  };

  const getReviews = () => {
    Config.get(`/user/reviews`)
      .then((res) => {
        if (res.status) {
          setReviewData(res.data);
          loaderMainWrap.current.style.display = "none";
        } else {
          console.log("getReviews");
          loaderMainWrap.current.style.display = "none";
        }
      })
      .catch((err) => {
        console.log(err, "getReviews");
        loaderMainWrap.current.style.display = "none";
      });
  };

  const lawyerData = () => {
    setLoading(true);
    Config.get(`/user/meet-lawyers-four`)
      .then((res) => {
        if (res.status) {
          setData(res.data);
          setLoading(false);
        } else {
          console.log("lawyerData");
        }
      })
      .catch((err) => {
        console.log(err, "lawyerData");
      });
  };

  const legalServiceData = () => {
    setLoading(true);
    Config.get(`/user/legal-services?limit=10`)
      .then((res) => {
        if (res.status) {
          setDataforlegalservice(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "legalServiceData");
      });
  };

  const [count, setCount] = useState(0);

  const filterLawyerCategory = (categoryId, index) => {
    // setLoadingDoc(true);
    document.getElementById("li" + count).className =
      "service-line lift position-relative  service-line-sm box-css ";
    document.getElementById("li" + index).className =
      "service-line lift position-relative  service-line-sm box-css active";

    setCount(index);

    Config.get(`/user/legal-doc?categoryId=${categoryId}&limit=4`)
      .then((res) => {
        if (res.status) {
          // setLoadingDoc(false);
          setLegalDocs(res.data);
        } else {
          console.log("filterLawyerCategory");
        }
      })
      .catch((err) => {
        console.log(err, "filterLawyerCategory");
      });
  };

  const getLegalCategories = () => {
    setLoading(true);
    Config.get(`/user/legal-doc-category`)
      .then((res) => {
        if (res.status) {
          setLoading(false);
          setLegalCategoryData(res.data);
          filterLawyerCategory(res.data[0]._id, 0);
        } else {
          console.log("error in getLegalCategories");
        }
      })
      .catch((err) => {
        console.log(err, "getLegalCategories");
      });
  };

  const isMember = localStorage.getItem("isMember") === "1";
  let buyDocument = false;
  if (isMember) buyDocument = true;

  const [isSearch, setIsSearch] = useState(true);

  // searching function

  const cancleSearch = () => {
    setIsSearch(true);
    document.getElementById("allDataFromSearch").innerHTML = "";
  };

  const searchFunAPI = function (fn, d) {
    console.log(d);
    let timer;
    return function () {
      let context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, arguments);
      }, d);
    };
  };

  const searchFun = (search) => {
    faSpinInner.current.style.display = "inline";

    faSpinInner.current.style.display = "inline";
    if (search === "") {
      Config.showToast("Nothing to search!!", "danger");
      setTimeout(() => {
        faSpinInner.current.style.display = "none";
      }, 300);
      return;
    }
    setIsSearch(false);
    Config.get(`/user/home-search?search=${search}`)
      .then((res) => {
        console.log(res);
        if (res.status) {
          if (res.documents.length == 0 && res.blogs.length == 0) {
            document.getElementById("allDataFromSearch").innerHTML = "";
            Config.showToast("Search result not found.", "danger");
            setTimeout(() => {
              faSpinInner.current.style.display = "none";
            }, 200);
            return;
          }
          document.getElementById("allDataFromSearch").innerHTML = "";
          let inputNode = ``;

          inputNode = document.getElementById("allDataFromSearch");

          var loop = 0;
          if (res.documents.length > 0) {
            const div_documents = document.createElement("div");
            div_documents.classList.add("autocomplete-items-ul");
            div_documents.innerHTML = "Documents";
            inputNode.appendChild(div_documents);

            while (res.documents.length > loop) {
              var doc_ul = document.createElement("ul");
              var doc_li = document.createElement("li");
              var doc_a = document.createElement("a");

              doc_a.setAttribute("data-type", "doc");
              doc_a.setAttribute("data-id", res.documents[loop].categoryId);
              doc_a.setAttribute("data-name", res.documents[loop].category);
              doc_a.classList.add("autocomplete-items");
              doc_a.innerHTML =
                res.documents[loop].name +
                " (" +
                res.documents[loop].category +
                ")";
              doc_li.appendChild(doc_a);
              doc_ul.appendChild(doc_li);
              inputNode.appendChild(doc_ul);
              loop++;
            }
          }

          loop = 0;

          if (res.blogs.length > loop) {
            const div_blogs = document.createElement("div");
            div_blogs.innerHTML = "Blogs";
            div_blogs.classList.add("autocomplete-items-ul");
            inputNode.appendChild(div_blogs);

            while (res.blogs.length > loop) {
              var blog_ul = document.createElement("a");

              var blog_ul = document.createElement("ul");
              var blog_li = document.createElement("li");
              var blog_a = document.createElement("a");

              blog_a.setAttribute("data-id", res.blogs[loop]._id);
              blog_a.setAttribute("data-name", res.blogs[loop].title);
              blog_a.setAttribute("data-type", "blog");
              blog_a.classList.add("autocomplete-items");
              blog_a.innerHTML =
                res.blogs[loop].name + res.blogs[loop].category[0];

              blog_li.appendChild(blog_a);
              blog_ul.appendChild(blog_li);
              inputNode.appendChild(blog_ul);

              loop++;
            }
          }

          document
            .querySelector("#allDataFromSearch")
            .addEventListener("click", searchClicked);

          if (search != "") setIsSearch(false);
          else setIsSearch(true);
          setTimeout(() => {
            faSpinInner.current.style.display = "none";
          }, 100);
        } else {
          setIsSearch(true);
          setTimeout(() => {
            faSpinInner.current.style.display = "none";
          }, 700);
        }
      })
      .catch((err) => {
        console.log(err, "Search Fun");
        setIsSearch(true);
        setTimeout(() => {
          faSpinInner.current.style.display = "none";
        }, 700);
      });
  };

  const searchFunCall = searchFunAPI(searchFun, 500);

  const searchClicked = (e) => {
    if (e.target.getAttribute("data-type") == "blog") {
      const slug = createSlug(e.target.getAttribute("data-name"));
      navigate(`/blogs/${slug}`);
    } else if (e.target.getAttribute("data-type") == "doc") {
      const slug = createSlug(e.target.getAttribute("data-name"));
      navigate(`/legal-document/${slug}`);
    }
  };
  const handleResize = () => {
    getWindowDimensions();
  };
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    setDeminsions({
      width,
      height,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    if (localStorage.getItem("token") == "undefined") {
      Config.showToast("Please login first", "danger");
      localStorage.clear();
      navigate("/signin");
    }
    document.getElementsByClassName("has-topbar")[0].style.display = "block";
    faSpinInner.current.style.display = "none";

    document.getElementById("checkout_process_css").href = "";
    document.getElementById("agreement_css").href = "";

    const script = document.createElement("script");
    script.src = "assets/js/controllers/swiper.js"; //(This is external js url)
    document.body.appendChild(script);

    document.getElementsByClassName("slick-track")[0].style.webkitTransform =
      "translate3d(20px, 0px, 0px)";

    console.log(
      document.getElementsByClassName("slick-track")[0].style.webkitTransform
    );

    document.title = "Home";
    if (sessionStorage.getItem("fromAgrement") == "true") {
      window.scrollTo(0, 950);
      sessionStorage.removeItem("fromAgrement");
    }
    legalDocData();
    getLegalCategories();
    lawyerData();
    legalServiceData();
    getPlans();
    getReviews();

    for (let k in window.localStorage) {
      if (
        k === "user_id" ||
        k === "token" ||
        k === "isMember" ||
        k === "plan_id" ||
        k === "email" ||
        k === "billingName" ||
        k === "guestId" ||
        k === "uniqueId" ||
        k === "cartCount" ||
        k.includes("twk") === true
        // k === "ppp"
        // k === "legalDocName"
      )
        continue;
      delete window.localStorage[k];
    }
  }, []);

  return (
    <>
      <div className="content-wrap ">
        <div className="circles">
          <div className="circle-1" />
          <div className="circle-2" />
          <div className="circle-3" />
        </div>
        <div className="pt-90 pb-90 shape-parent">
          <div className="container top_section">
            <div className="shape justify-content-start align-items-end">
              <img
                loading="lazy"
                // src="/assets/img/half_circule.png"
                src="/assets/img/svg/half_circule.svg"
                alt=""
                width={364}
                height={300}
              />
            </div>
            <div className="row gy-70">
              <div className="col-lg-12 col-md-10 offset-lg-1 offset-md-1 top-text">
                <div className="search-field-1">
                  <div className="search-icon-1">
                    {isSearch ? (
                      <button className="search-btn-1">
                        {" "}
                        <img src="/assets/img/search-icon.png" alt="" />{" "}
                      </button>
                    ) : (
                      <button
                        className="cancel-btn-1"
                        onClick={() => {
                          const d = document.getElementById(
                            "inlineFormInputGroupUsername"
                          );
                          d.value = "";

                          cancleSearch();
                        }}
                      >
                        <img src="/assets/img/svg/close.svg" alt="" />{" "}
                      </button>
                    )}
                  </div>
                  <input
                    type="text"
                    className="search-input-1"
                    id="inlineFormInputGroupUsername"
                    placeholder="Search topics and documents"
                    onChange={(e) => {
                      searchFunCall(e.target.value);
                    }}
                  />
                  <i
                    className="fa fa-spinner fa-spin-inner fa-spin search-spinner"
                    ref={faSpinInner}
                    aria-hidden="true"
                  ></i>
                </div>
                {deminsions.width < 1024 && (
                  <div>
                    <div id="allDataFromSearch" className="mobileResult"></div>
                  </div>
                )}
                <div className="title-logo">
                  <h1 className=" mb-45">Legal services made simple</h1>
                  <img className="logo" src="/assets/img/logo.png" />
                </div>

                <p className="mb-70 font-size-18 fw-medium pe-lg-30">
                  Law For Me offers expert legal advice, document creation, and
                  dispute resolution without the need for a lawyer. This saves
                  time and money while simplifying complex areas of the law. Our
                  platform also provides access to city lawyers with live video
                  consultations and chat. We offer a virtual dispute resolution
                  platform using mediators or arbitrators to resolve disputes,
                  avoiding the stress and expense of Court. Our innovative
                  approach provides new thinking in the legal industry.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-8">
                <div className="search-contact">
                  <div
                    className="search-field"
                    style={{
                      display: deminsions.width < 1024 ? "none" : "flex",
                    }}
                  >
                    <div className="search-icon">
                      {isSearch ? (
                        <button className="search-btn">
                          {" "}
                          <img src="/assets/img/search-icon.png" alt="" />{" "}
                        </button>
                      ) : (
                        <button
                          className="cancel-btn"
                          onClick={() => {
                            const d = document.getElementById(
                              "inlineFormInputGroupUsername"
                            );
                            d.value = "";

                            cancleSearch();
                          }}
                        >
                          <img src="/assets/img/svg/close.svg" alt="" />{" "}
                        </button>
                      )}
                    </div>
                    <input
                      type="text"
                      className="search-input"
                      id="inlineFormInputGroupUsername"
                      placeholder="Search topics and documents"
                      onChange={(e) => {
                        searchFunCall(e.target.value);
                      }}
                    />
                    <i
                      className="fa fa-spinner fa-spin-inner fa-spin search-spinner"
                      ref={faSpinInner}
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div>
                    <button className="contactUs-btn">
                      <Link className="" to="/contact-us">
                        <a className="contact-btn-text">Contact Us</a>
                      </Link>
                    </button>
                  </div>
                  {deminsions.width >= 1024 && (
                    <div className="search-mobileresult">
                      <div id="allDataFromSearch"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-12">
                <div className="cards">
                  <div className="column">
                    <video
                      className="video-container"
                      src="/assets/video/home-video.mp4"
                      muted
                      autoPlay
                      loop
                    />
                  </div>

                  <div className="column">
                    <div className="image-container">
                      <img
                        src="/assets/img/svg/legalDoc.jfif"
                        alt="Legal Document"
                      />
                      <div className="overlay-container">
                        <div className="text-overlay">Legal Documents</div>
                        <div className="service-name-container">
                          {legalNav.map((service) => (
                            <div
                              className="service-names"
                              data-serviceid={service._id}
                              onClick={(e) => {
                                const slug = createSlug(service.name);
                                navigate(`/legal-document/${slug}`);
                              }}
                              key={service._id}
                            >
                              {service.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="column">
                    <div className="image-container">
                      <img
                        src="/assets/img/svg/legalService.jpg"
                        alt="Legal Document"
                      />
                      <div className="overlay-container">
                        <div className="text-overlay">Legal Services</div>
                        <div className="service-name-container">
                          {dataforlegalservice.map((service) => (
                            <div
                              className="service-names"
                              data-serviceid={service._id}
                              onClick={() => {
                                const slug = createSlug(service.name);
                                serviceFun(slug);
                              }}
                              key={service._id}
                            >
                              {service.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="column">
                    <div className="image-container">
                      <img
                        src="/assets/img/svg/subscriptionPlan.jfif"
                        alt="Legal Document"
                      />
                      <div className="overlay-container">
                        <div className="text-overlay">Subscription Plans</div>
                        <div className="service-name-container">
                          <div className="service-names" onClick={() => {
                                navigate(`/pricing`);
                              }}>
                            Subscription Plans                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? (
        <></>
      ) : (
        <>
          <div className="pt-20 pt-lg-50 pb-lg-130 pb-10">
            <div className="container">
              <div className="row mb-50">
                <div className="col-lg-10 col-md-10 offset-lg-1 offset-md-1 text-center">
                  <h2 className="m-0 px-lg-70">Legal Documents</h2>
                  <p className="mt-20 text-justify">
                    Create bespoke legal/HR documents, contracts, letters, and
                    court forms/documents without using a lawyer. Our technology
                    mimics the most expert city lawyers when creating these
                    documents so you can ensure that the documents are
                    incredibly accurate and created by experts with tips,
                    explanations, and suggestions so you know what they mean
                    just like a lawyer would advise you.
                  </p>
                </div>
              </div>
              <div className="row gy-10" id="legal-documents-tab">
                {/* Item*/}
                {legalCategoryData.map((data, index) => (
                  <ServiceLine
                    key={index}
                    id={index}
                    src={svg[data.icon][0]}
                    src1={svg[data.icon][1]}
                    serviceLineTitle={data.name}
                    serviceLineTitle1={data._id}
                    isActive={index === 0 ? "active" : ""}
                    funOnClick={filterLawyerCategory}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className=" pb-50 how_work_div" id="flex-direction">
            <div className="container">
              <div className="row gy-lg-50 gy-0" style={{}}>
                <div className="row mb-lg-50 mb-0" style={{ display: "flex" }}>
                  <div className="col-lg-5 col-md-5 col-sm-5">
                    <h3
                      className="mb-10 animated"
                      style={{
                        transform: "translateY(30px)",
                        transitionDuration: "500ms",
                        opacity: 1,
                        padding: "0px 10px",
                      }}
                    >
                      How it Works
                    </h3>
                    <p
                      className="mb-40 animated"
                      style={{
                        transform: "translateY(30px)",
                        transitionDuration: "500ms",
                        opacity: 1,
                        padding: "0px 10px",
                      }}
                    >
                      Easy to use, expertly created and always knowing what they
                      mean
                    </p>
                    <div style={{ marginTop: "10px" }}>
                      <video
                        src="/assets/video/LFM _ video 2 _ V3.mp4"
                        muted
                        autoPlay
                        loop
                        height="100%"
                        width="100%"
                        class="w-100 rounded-4 shadow mb-lg-n210 rellax no-transform-lg dummey-image"
                      ></video>
                    </div>
                  </div>

                  <div className="col-lg-7 col-md-7 offset-lg-0 col-sm-7">
                    <div className="row mb-lg-50 mb-0">
                      {legalDocs.map((data, index) => (
                        <Feedback
                          key={index}
                          _id={data._id}
                          src="/assets/img/svg/latter.svg"
                          redirectPage="/checkout-multiple-cart"
                          feedbackText={data.documentType}
                          feedbackText1={data.name}
                          price={data.price}
                          btnText="Buy Now"
                          btnText1={
                            buyDocument ? "Add to Document" : "Access for Free"
                          }
                        />
                      ))}

                      {legalDocs.length === 0 && (
                        <div className="row mb-50 p-50 text-justify">
                          <h2 className="mb-20 top-heading">
                            Couldn't find the document you are looking for?
                          </h2>
                          <p>
                            If you cannot find a document which suits your
                            needs, we have a team of expert lawyers who can
                            create bespoke agreements customised to your
                            requirements.
                          </p>

                          <a
                            href="/contact-us"
                            onClick={(e) => {
                              e.preventDefault();
                              window.scrollTo(0, 0);
                              navigate("/contact-us/bespoke-agreements");
                            }}
                            className="btn btn btn-sm btn-accent-1 ms-20 me-30 me-lg-0 order-2 order-lg-3 mt-20 w-auto"
                          >
                            Enquire Now
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="pt-lg-50 pt-20 pb-lg-50 pb-10"
            style={{
              background: "rgba(31, 170, 255, 0.1)",
              boxShadow: "0px 60px 80px rgba(87, 152, 192, 0.02)",
            }}
          >
            <div className="container">
              <div className="row mb-0">
                <div className="col-lg-7 col-md-10 offset-lg-3 offset-md-1 text-center">
                  <h2 className="m-0 px-lg-70">Subscription Plans</h2>
                  <p className="mt-20 mb-0">
                    Imagine having unlimited up-to-date legal documents, expert
                    city lawyers whenever needed, and full access to the 'My
                    Legal Hub' platform and much more
                  </p>
                  <p className="mt-20 mb-0">
                    By engaging in our services, you acknowledge that our legal
                    services are not regulated
                  </p>
                </div>
              </div>
            </div>
            {/* <div className="pt-lg-10 pt-20 pb-60"> */}
            <div className="container gy-10">
              <div className="pricing">
                <div className="row">
                  <div className="pricing-table table-responsive">
                    <table className="table">
                      {/* Heading */}
                      <thead>
                        <tr>
                          <th>
                            <div className="table_div" style={{}}>
                              Want a bespoke plan or have your entire legal/HR
                              outsourced to us?
                              <br />
                              <a
                                href="/request-call-back"
                                onClick={(e) => {
                                  window.scrollTo(0, 0);
                                  e.preventDefault();
                                  navigate("/request-call-back");
                                }}
                                className="btn d-sm-inline-flex btn btn-sm btn-accent-1 ms-auto me-30 me-lg-0 order-2 order-lg-3 mt-6"
                                target="_self"
                              >
                                Request a Call Back Today
                              </a>
                            </div>
                          </th>
                          <th className="text-center">
                            <div
                              style={{
                                background: "#1FAAFF",
                                color: "#fff",
                                borderRadius: "15px",
                                padding: "21px",
                              }}
                            >
                              <span className="font-size-14">Access Plan</span>{" "}
                              <br />
                              <span className="ptable-star red">£99</span>{" "}
                              <br />
                              <span className="ptable-price font-size-11">
                                + VAT
                              </span>
                            </div>
                          </th>
                          <th className="text-center">
                            <div
                              style={{
                                background: "#FE1DB3",
                                color: "#fff",
                                borderRadius: "15px",
                                padding: "21px",
                              }}
                            >
                              <span className="font-size-14">Growth Plan</span>{" "}
                              <br />
                              <span className="ptable-star red">£250</span>{" "}
                              <br />
                              <span className="ptable-price font-size-11">
                                + VAT
                              </span>
                            </div>
                          </th>
                          <th className="text-center">
                            <div
                              style={{
                                background: "#1FAAFF",
                                color: "#fff",
                                borderRadius: "15px",
                                padding: "14px 14px",
                                position: "relative",
                              }}
                            >
                              <div className="plan" style={{}}>
                                <img
                                  src="/assets/img/svg/plan_icon.svg"
                                  alt=""
                                  style={{ width: "15px" }}
                                />{" "}
                                Most Popular
                              </div>
                              <div className="plan-text">
                                <span className="font-size-14">
                                  Professional Plan
                                </span>{" "}
                                <br />
                                <span className="ptable-star red">
                                  £650
                                </span>{" "}
                                <br />
                                <span className="ptable-price font-size-11">
                                  + VAT
                                </span>
                              </div>
                            </div>
                          </th>
                          <th className="text-center">
                            <div
                              style={{
                                background: "#FE1DB3",
                                color: "#fff",
                                borderRadius: "15px",
                                padding: "21px",
                              }}
                            >
                              <span className="font-size-14">
                                Enterprise Plan
                              </span>{" "}
                              <br />
                              <span className="ptable-star red">
                                £1250
                              </span>{" "}
                              <br />
                              <span className="ptable-price font-size-11">
                                + VAT
                              </span>
                            </div>
                          </th>
                          <th className="text-center">
                            <div
                              style={{
                                background: "#FE1DB3",
                                color: "#fff",
                                borderRadius: "15px",
                                padding: "10px",
                              }}
                            >
                              <span className="font-size-14">
                                Outsourced Plan
                              </span>{" "}
                              <br />
                              <span className="ptable-star red">
                                £2950
                              </span>{" "}
                              <br />
                              <span className="ptable-price font-size-11">
                                + VAT
                              </span>
                              <br />
                              <span>
                                <button
                                  className="openModalBtn"
                                  onClick={() => {
                                    setModalOpen(true);
                                  }}
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: "700",
                                    outline: "none",
                                    border: "none",
                                    borderRadius: "5px",
                                    padding: "5px",
                                    background: "#1FAAFF",
                                    color: "#FFFFFF",
                                  }}
                                >
                                  View Details
                                </button>
                                {modalOpen && (
                                  <OutSourcedModal
                                    setOpenModal={setModalOpen}
                                    plan={allPlan}
                                    show={modalOpen}
                                  />
                                )}
                              </span>
                            </div>
                          </th>
                          {allPlan.length > 5 && (
                            <th className="text-center">
                              <div
                                style={{
                                  background: "#FE1DB3",
                                  color: "#fff",
                                  borderRadius: "15px",
                                  padding: "21px",
                                }}
                              >
                                <span className="font-size-14">Daily Plan</span>{" "}
                                <br />
                                <span className="ptable-star red">
                                  £100
                                </span>{" "}
                                <br />
                                <span className="ptable-price font-size-11">
                                  + VAT
                                </span>
                              </div>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div style={{}} className="table_div">
                              <span className="ptable-title">
                                Access to all legal documents (no additional
                                cost + editable files)
                              </span>
                            </div>
                          </td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="table_div" style={{}}>
                              <span className="ptable-title">
                                Added to the LFM International Partners
                                Programme
                              </span>
                            </div>
                          </td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="table_div" style={{}}>
                              <span className="ptable-title">
                                Annual 60 minute Business Coaching/HR
                                Consultation/Tax Advisory session
                              </span>
                            </div>
                          </td>
                          <td></td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="table_div" style={{}}>
                              <span className="ptable-title">
                                Free 15-minute consultation per matter
                              </span>
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{}} className="table_div">
                              <span className="ptable-title">
                                15% off additional hours of support
                              </span>
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                          <td>
                            <img
                              src="/assets/img/svg/Vector_tick.svg"
                              alt=""
                              style={{ background: "#fff" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div style={{}} className="table_div">
                              <span className="ptable-title">
                                Hours of legal support annually
                              </span>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <b>0</b>
                          </td>
                          <td>
                            {" "}
                            <b>15</b>
                          </td>
                          <td>
                            {" "}
                            <b>30</b>
                          </td>
                          <td>
                            {" "}
                            <b>50</b>
                          </td>
                          <td>
                            {" "}
                            <b>416</b>
                          </td>
                        </tr>
                        <tr>
                          <td />

                          {allPlan.map((data, index) => (
                            <td className="last-row" key={index}>
                              <a
                                id={index}
                                className={`btn btn btn-sm btn-accent-${
                                  index % 2 === 0 ? "2" : "1"
                                } ms-auto me-30 me-lg-0 order-2 order-lg-3 ${
                                  plan_id === data._id ? "disabled" : ""
                                }
                                  ${
                                    localStorage.getItem("isMember") == 1 &&
                                    isTrue == true &&
                                    plan_id !== data._id
                                      ? "disabled REMOVEBTN"
                                      : ""
                                  }`}
                                onClick={(e) => {
                                  window.scrollTo(0, 0);
                                  localStorage.setItem(
                                    "pricing_id",
                                    data.stipePriceId
                                  );
                                  localStorage.setItem("price", data.price);
                                  localStorage.setItem("planName", data.plan);
                                  localStorage.setItem("planId", data._id);

                                  return navigate(
                                    "/checkout-subscription-plan"
                                  );
                                }}
                              >
                                {localStorage.getItem("isMember") == 0 ||
                                localStorage.getItem("isMember") == undefined
                                  ? "Get Started"
                                  : plan_id === data._id
                                  ? (isTrue = false)
                                  : ""}
                                {localStorage.getItem("isMember") == 1
                                  ? plan_id === data._id
                                    ? "Current Plan"
                                    : isTrue == false
                                    ? "Upgrade"
                                    : ""
                                  : ""}
                              </a>
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </>
      )}
      <div className="pt-lg-120 pt-20 pb-50 pb-sm-100">
        <div className="container">
          <div className="row mb-90">
            <div className="col-lg-10 col-md-10 ">
              <h2 className="mb-8 animated" style={{ opacity: 1 }}>
                Legal Services &amp; Advisory
                {/* Legal Services Made Simple */}
              </h2>
              <p className=" text-dark mb-20">
                We are more than just lawyers. We are your trusted advisors,
                partners, and we are passionate about adding real value to your
                business in many different ways.
              </p>
            </div>
            <div className="col-lg-2 col-md-2"></div>
          </div>
          {/* Slider*/}
          <div className="newslider">
            <Slider {...legalServiceSlider}>
              {dataforlegalservice.map((legalservice, index) => (
                <LegalServiceCom
                  key={index}
                  myKey={index}
                  src={legalservice.bannerMedia}
                  type={legalservice.name}
                  uniqueId={legalservice._id}
                />
              ))}
            </Slider>
          </div>
          {/* end's here */}
        </div>
      </div>
      <div className="pt-20 pt-lg-20 pb-lg-60 pb-10">
        <div className="container">
          <div className="row mb-50">
            <div className="col-lg-10 col-md-9 ">
              <h2 className="mb-5">Meet the Lawyers</h2>
              <p className=" text-dark mb-20">
                We have a wealth of experience. We only hire specialist lawyers
                that know what they are talking about. All our lawyers are
                self-employed and so take pride in their service delivery and
                have outstanding feedback from our clients.
              </p>
            </div>
            <div className="col-lg-2 col-md-3">
              <a
                className="btn btn btn-sm btn-accent-1 ms-auto me-30 me-lg-0 order-2 order-lg-3"
                onClick={(e) => {
                  e.preventDefault();
                  window.scrollTo(0, 0);
                  navigate("/meet-the-lawyer");
                }}
              >
                View All
              </a>
            </div>
          </div>
          {/* Slider*/}
          {/* dmobnone have to add class below for mobile view*/}
          <div className="row gy-30 pt-10 pt-lg-50 dmobnone">
            {data ? (
              data.map((lawyer) => (
                <OurTeam
                  key={lawyer._id}
                  src={lawyer.profilePicture}
                  name={lawyer.name}
                  lastName={lawyer.lastName}
                  data1={lawyer.position}
                  data2={lawyer.secondaryPosition}
                  data3={lawyer.qualificationOverView}
                  data4="More About Me"
                  uniqueId={lawyer._id}
                />
              ))
            ) : (
              <div className={"container"}>
                <div className={"loading-icon"}>
                  <center>
                    <img src={`${Config.Path}loading-icon.gif`} alt="img" />
                  </center>
                </div>
              </div>
            )}
          </div>
          {data.length > 0 && (
            <div className="row dmob">
              <div
                className="swiper mt-30 customers-box animated"
                data-swiper-slides={1}
                data-swiper-breakpoints="576:1, 1228:2, 992:2"
                data-swiper-gap={30}
                data-swiper-grabcursor="true"
                style={{
                  transform: "translateY(0px)",
                  transitionDuration: "500ms",
                  opacity: 1,
                }}
              >
                <OurTeam
                  src={data[selectedLawyer].profilePicture}
                  name={data[selectedLawyer].name}
                  lastName={data[selectedLawyer].lastName}
                  data1={data[selectedLawyer].position}
                  data2={data[selectedLawyer].secondaryPosition}
                  data3={data[selectedLawyer].qualificationOverView}
                  data4="More About Me"
                  uniqueId={data[selectedLawyer]._id}
                />

                <div className="d-flex justify-content-center slider_btn_mob">
                  <div
                    className={`swiper-button-prev swiper-button-position-2 swiper-button-gray shadow ${
                      selectedLawyer === 0 ? "swiper-button-disabled" : ""
                    }`}
                    tabIndex={0}
                    onClick={(e) => {
                      e.preventDefault();
                      if (selectedLawyer > 0)
                        setSelectedLawyer((prev) => prev - 1);
                    }}
                    role="button"
                    aria-label="Previous slide"
                    aria-controls="swiper-wrapper-9f5ac1a754f7558b"
                    aria-disabled="false"
                  >
                    <svg
                      width={10}
                      height={18}
                      viewBox="0 0 10 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.84793 8.71611C1.92813 8.65701 1.99145 8.62324 2.03788 8.57681C4.46505 6.1623 6.888 3.74358 9.31095 1.3333C9.53467 1.10958 9.6613 0.864748 9.55155 0.548161C9.40803 0.117603 8.87617 -0.0385802 8.51737 0.244237C8.45827 0.29067 8.40762 0.341324 8.35696 0.391978C5.76095 2.97955 3.16916 5.5629 0.573141 8.15047C0.16791 8.5557 0.167909 8.90184 0.577362 9.31129C3.17338 11.8989 5.77361 14.4907 8.36963 17.0824C8.57646 17.2893 8.80441 17.4159 9.10833 17.3315C9.55577 17.2006 9.73728 16.6814 9.46713 16.3015C9.41647 16.2298 9.34894 16.1665 9.28562 16.1031C6.87533 13.6971 4.46505 11.2952 2.05055 8.89339C2.00411 8.84274 1.94924 8.80053 1.84793 8.71611Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                  <div
                    className={`swiper-button-next swiper-button-position-2 swiper-button-gray shadow ${
                      data.length - 1 === selectedLawyer
                        ? "swiper-button-disabled"
                        : ""
                    }`}
                    tabIndex={0}
                    onClick={(e) => {
                      e.preventDefault();
                      if (selectedLawyer < data.length - 1)
                        setSelectedLawyer((prev) => prev + 1);
                    }}
                    role="button"
                    aria-label="Next slide"
                    aria-controls="swiper-wrapper-9f5ac1a754f7558b"
                    aria-disabled="false"
                  >
                    <svg
                      width={11}
                      height={18}
                      viewBox="0 0 11 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.59885 8.71611C8.51864 8.65701 8.45533 8.62324 8.40889 8.57681C5.98173 6.1623 3.55878 3.74358 1.13583 1.3333C0.912111 1.10958 0.785476 0.864748 0.895226 0.548161C1.03875 0.117603 1.57061 -0.0385802 1.92941 0.244237C1.98851 0.29067 2.03916 0.341324 2.08981 0.391978C4.68583 2.97955 7.27762 5.5629 9.87364 8.15047C10.2789 8.5557 10.2789 8.90184 9.86942 9.31129C7.2734 11.8989 4.67317 14.4907 2.07715 17.0824C1.87031 17.2893 1.64237 17.4159 1.33845 17.3315C0.891005 17.2006 0.709495 16.6814 0.979649 16.3015C1.0303 16.2298 1.09784 16.1665 1.16116 16.1031C3.57144 13.6971 5.98173 11.2952 8.39623 8.89339C8.44266 8.84274 8.49754 8.80053 8.59885 8.71611Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="pt-10 pb-10 callToActionPrev">
        <div className="container">
          <div className="row gy-30">
            <div className="col-sm-6 col-md-6 col-lg-6">
              {/* Service case*/}
              <div className="service-case lift rounded-4 bg-white overflow-hidden ">
                <a
                  className="service-case-image newhyphanimage-64"
                  data-img-height
                  style={{ margin: "30px", borderRadius: "8px" }}
                >
                  {/* <img loading="lazy" src="/assets/img/img1.png" alt="" /> */}
                  <img loading="lazy" src="/assets/img/svg/img1.svg" alt="" />
                </a>
                <div className="service-case-body position-relative">
                  <h2 className="service-case-title mb-15">
                    How To Guides, Videos & Articles
                  </h2>
                  <p className="service-case-text font-size-15 mb-30">
                    We have developed a room full of articles, videos, and how
                    to guides to help you navigate the law, improve your
                    business and mitigate or deal with disputes in the right
                    way.
                  </p>
                  <a
                    href="/blogs"
                    onClick={(e) => {
                      window.scrollTo(0, 0);
                      e.preventDefault();
                      navigate("/blogs");
                    }}
                    className="btn btn btn-sm btn-accent-1 ms-auto me-30 me-lg-0 order-2 order-lg-3"
                  >
                    Blogs
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6">
              {/* Service case*/}
              <div className="service-case-body position-relative">
                <h2 className="service-case-title mb-15">
                  Our Key Highlights {/* Some Information About Law For Me */}
                </h2>
                <p className="service-case-text font-size-15 mb-30">
                  We have developed a world-class legal tech platform that is
                  unrivalled in the market and have built a large team of highly
                  experienced and motivated lawyers. We work with the best city
                  law firms in the country for any regulated legal work.
                </p>
                <Link
                  to="/about-us"
                  className="btn btn btn-sm btn-accent-1 ms-auto me-30 me-lg-0 order-2 order-lg-3"
                >
                  How We Work
                </Link>
                <div className="row">
                  <div className="col-lg-6 col-md-5 col-sm-5 col-xs-5 text-center">
                    <img
                      // src="/assets/img/img2.png"
                      src="/assets/img/svg/img2.svg"
                      className="info_img"
                      alt=""
                      style={{ borderRadius: "120px 120px 0px 0px" }}
                    />
                  </div>
                  <div className="col-lg-6 col-md-7 col-sm-7 col-xs-7 text-center">
                    <img
                      // src="/assets/img/img3.png"
                      src="/assets/img/svg/img3.svg"
                      alt=""
                      className="info_img2"
                      style={{ borderRadius: "120px 120px 0px 0px" }}
                    />
                  </div>
                  <div
                    className="col-md-10 offset-md-1 d-flex col-sm-12 transparent_box"
                    style={{}}
                  >
                    <div className="col-2 px-0 text-center">
                      <img
                        src="/assets/img/svg/review_vector.svg"
                        alt=""
                        width="20px"
                      />
                      <img
                        src="/assets/img/svg/review_vector.svg"
                        alt=""
                        width="20px"
                      />
                    </div>
                    <div className="col-10 px-0">
                      <h5 className="mb-1">
                        Bhavika, Sage, and Kishan provided me with exemplary
                        services.
                      </h5>
                      <p className="mb-1" style={{ fontSize: "14px" }}>
                        Their advice was spot-on, fast acting, and proactive.
                        They lay out your options clearly and guide you to make
                        the best decisions. I can't recommend them highly
                        enough. They were simply there when I needed them.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Call to action*/}
      <JoinOurTeam />

      <div
        className="pt-180 pb-20 pb-lg-130 footerPrev footerPrev-mob"
        id="review"
      >
        <div className="container">
          <div className="row mb-30 review-margin">
            <div className="col-lg-6 col-md-8 ">
              <h2 className="mb-10" data-show="startbox" data-show-delay={100}>
                What our customers say about Law For Me
              </h2>
            </div>
            <div className="col-lg-2 col-md-2 "></div>
          </div>
          {/* Slider*/}
          <div className="reviwslider">
            <Slider {...reviewSlider}>
              {reviewData.map((data) => (
                <div className="swiper-slide h-auto col-md-6 col-sm-12 swiper-slide-active">
                  {/* Feedback*/}
                  {data.comments.length > 300 ? (
                    <div
                      className="feedback bg-white rounded-4 h-100 shadow feedback-mob review-scrollbar"
                      style={{ overflowY: "scroll" }}
                    >
                      <div className="feedback-stars lh-1 mb-30 d-flex">
                        <img
                          src="https://dev.lawforme.co.uk/html/website/assets/img/svg/review_vector.svg"
                          alt=""
                        />
                        <img
                          src="https://dev.lawforme.co.uk/html/website/assets/img/svg/review_vector.svg"
                          alt=""
                        />
                      </div>
                      <p className="feedback-text fw-medium text-dark m-0 text-justify">
                        {data.comments}
                      </p>
                      <div className="feedback-header d-flex align-items-center mb-35">
                        <div className="flex-grow-1 mt-20">
                          <h6 className="feedback-author mb-5">{data.user}</h6>
                          <div className="feedback-stars lh-1 mb-30">
                            {data.rating == 1 && (
                              <img src="/assets/img/svg/one_star.svg" alt="" />
                            )}
                            {data.rating == 2 && (
                              <img src="/assets/img/svg/two_star.svg" alt="" />
                            )}
                            {data.rating == 3 && (
                              <img
                                src="/assets/img/svg/three_star.svg"
                                alt=""
                              />
                            )}
                            {data.rating == 4 && (
                              <img src="/assets/img/svg/four_star.svg" alt="" />
                            )}
                            {data.rating == 5 && (
                              <img src="/assets/img/svg/five_star.svg" alt="" />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="review-said"></div>
                    </div>
                  ) : (
                    <div
                      className="feedback bg-white rounded-4 h-100 shadow feedback-mob"
                      style={{ overflowY: "unset" }}
                    >
                      <div className="feedback-stars lh-1 mb-30 d-flex">
                        <img
                          src="https://dev.lawforme.co.uk/html/website/assets/img/svg/review_vector.svg"
                          alt=""
                        />
                        <img
                          src="https://dev.lawforme.co.uk/html/website/assets/img/svg/review_vector.svg"
                          alt=""
                        />
                      </div>
                      <p className="feedback-text fw-medium text-dark m-0 text-justify">
                        {data.comments}
                      </p>

                      <div className="feedback-header d-flex align-items-center mb-35">
                        <div className="flex-grow-1 mt-20">
                          <h6 className="feedback-author mb-5">{data.user}</h6>
                          <div className="feedback-stars lh-1 mb-30">
                            {data.rating == 1 && (
                              <img src="/assets/img/svg/one_star.svg" alt="" />
                            )}
                            {data.rating == 2 && (
                              <img src="/assets/img/svg/two_star.svg" alt="" />
                            )}
                            {data.rating == 3 && (
                              <img
                                src="/assets/img/svg/three_star.svg"
                                alt=""
                              />
                            )}
                            {data.rating == 4 && (
                              <img src="/assets/img/svg/four_star.svg" alt="" />
                            )}
                            {data.rating == 5 && (
                              <img src="/assets/img/svg/five_star.svg" alt="" />
                            )}
                          </div>
                        </div>
                        {/* <div className="flex-shrink-0">
                      <img
                        className
                        loading="lazy"
                        src="https://dev.lawforme.co.uk/html/website/assets/img/dumlogo.png"
                        alt=""
                        height
                        width={120}
                      />
                    </div> */}
                      </div>
                      <div className="review-said"></div>
                    </div>
                  )}
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <div
        className="loader-main-wrap"
        style={{ display: "block" }}
        ref={loaderMainWrap}
      >
        <div className="loader-inner-wrap">
          <i className="fa fa-spinner fa-spin" aria-hidden="true" />
        </div>
      </div>
    </>
  );
};

export default Home;
